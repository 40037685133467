<template>
  <div class="viewDashBoard project-route-index">
    <transition
      name="fade"
      mode="out-in"
      @beforeLeave="beforeLeave"
      @enter="enter"
      @afterEnter="afterEnter"
    >
      <div key="1" v-if="loader" class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
      <div key="2" v-else class="white-row">
        <div class="show-project-portal-listing">
          <!-- Create Project Form  -->
          <div class="form-area">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-12">
                  <div class="row top-gen-heading">
                    <h1 class="global-style-heading">
                      Edit Project Status
                    </h1>
                  </div>
                </div>
                <div class="col-sm-12">
                  <form
                    class="status-form"
                    v-on:submit.prevent="onSubmit($event)"
                  >
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="form-group">
                            <label for="status_name"
                              >Status Name: <span>*</span></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Status Name"
                              v-model="formData.status_name"
                              :class="{
                                'input-border-errors':
                                  $v.formData.status_name.$dirty &&
                                  !$v.formData.status_name.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="form-group">
                            <label for="gender" class="control-label"
                              >Select Project Status Category Name:
                              <span>*</span></label
                            >
                            <Select2
                              :options="status_category_option"
                              :settings="{
                                placeholder:
                                  'Select Project Status Category Name',
                                allowClear: true
                              }"
                              v-model="formData.status_category_id"
                              :class="{
                                'input-border-errors':
                                  $v.formData.status_category_id.$dirty &&
                                  !$v.formData.status_category_id.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="form-actions">
                            <button
                              :disabled="disableSubmitBtn"
                              type="submit"
                              id="save-form"
                              class="btn btn-primary default-global-btn"
                            >
                              Update
                            </button>
                            <div
                              v-if="disableSubmitBtn"
                              class="form_submit_loader"
                            >
                              <div class="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Select2 from "v-select2-component";
import { validationMixin } from "vuelidate";
const {
  required,
  minLength,
  email,
  numeric,
  url
} = require("vuelidate/lib/validators");
export default {
  mixins: [validationMixin],
  components: {
    Select2
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  validations: {
    formData: {
      status_name: {
        required
      },
      status_category_id: {
        required
      }
    }
  },
  data() {
    return {
      loader: true,
      formData: {
        status_name: null,
        status_category_id: null,
        status_id: null
      },
      status_category_option: null,
      disableSubmitBtn: false
    };
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
    this.getProjectStatusCategory();
    this.getProjectStatusDetails();
    this.loader = false;
  },
  methods: {
    async getProjectStatusDetails() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "get-all-project-status/edit/" + this.$route.params.project_status_id
      });
      await data.then(function(response) {
        if (typeof response != "undefined") {
          $this.formData.status_id = response.data.data.id;
          $this.formData.status_name = response.data.data.status_name;
          $this.formData.status_category_id =
            response.data.data.project_status_cateogry_name;
        }
      });
    },
    async onSubmit(event) {
      const $this = this;
      $this.disableSubmitBtn = true;
      $this.$v.$touch();

      if ($this.$v.$invalid) {
        $this.$store.dispatch("showtostrMessageNotifications", {
          message: "Please fill all the fields to proceed form!",
          type: "error"
        });
        $this.disableSubmitBtn = false;
        return;
      }
      const data = $this.$store.dispatch("postHttp", {
        url: "get-all-project-status/update",
        formDetails: this.formData,
        formEvent: event.target
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.$store.dispatch("displayMessageErrors", {
            response: response,
            formEvent: event.target
          });
          // $this.$router.push({path: '/project-status'});
          $this.disableSubmitBtn = false;
        }
      });
      $this.disableSubmitBtn = false;
    },
    async getProjectStatusCategory() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "filters/get-project-status-caetgory-lists"
      });
      await data.then(function(response) {
        if (typeof response != "undefined") {
          $this.status_category_option = response.data.data;
        }
      });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    }
  }
};
</script>
<style lang="scss" scoped>
body .form-area .form-group label span {
  color: red;
}
body .white-row.loader-route {
  top: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.project-route-index {
  .show-project-portal-listing {
    .general-filters {
      .form-group {
        width: 100%;
      }
      .form-actions {
        width: 100%;
        button {
          width: 100%;
          margin-left: 0;
          padding: 5px 0;
        }
      }
      span.select2.select2-container.select2-container--default {
        width: 100% !important;
      }
    }
    h4 {
      letter-spacing: 0.28px;
      color: #323f54;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0;
      padding-bottom: 7px;
    }
    p {
      margin: 0;
      letter-spacing: 0.28px;
      color: #2a1c0b;
    }
    section {
      border-bottom: 1px solid #ebebeb;
      padding: 30px 0;
      &.client-projects {
        padding-bottom: 0px;
        h4 {
          padding: 5px 0 25px;
        }
        .wrap-box {
          background: #faf9f7 0% 0% no-repeat padding-box;
          border-radius: 3px;
          padding: 20px 15px;
          min-height: 231px;
          margin-bottom: 30px;
          .box {
            position: relative;
            .project-details {
              p {
                &.project-id {
                  background: #ece9df 0% 0% no-repeat padding-box;
                  border-radius: 3px;
                  display: inline-block;
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 4px 10px;
                }
                &.project-status {
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 3px 0 15px;
                  font-weight: 400;
                }
              }
              .project-listing {
                padding: 15px 0 15px;
                p {
                  margin: 0;
                  letter-spacing: 0.28px;
                  color: #323f54;
                  font-size: 14px;
                  font-weight: bold;
                  text-transform: inherit;
                  line-height: 25px;
                }
              }
            }
            .footer-box {
              display: block;
              position: relative;
              width: 100%;
              padding: 20px 0 10px;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
              .usr-role {
                p {
                  letter-spacing: 0.24px;
                  color: #918a82;
                  font-weight: 300;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
